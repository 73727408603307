*,
*::after,
*::before {
  box-sizing: border-box;
}

:root {
  font-size: 15px;
}

body {
  margin: 0;
  --color-text: #fff;
  --color-bg: #fff;
  --color-link: #6b6b6b;
  --color-link-hover: #fff;
  color: var(--color-text);
  background-color: var(--color-bg);
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

/* Page Loader */
.js .loading::before,
.js .loading::after {
  content: '';
  position: fixed;
  z-index: 1000;
}

.js .loading::before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color-bg);
}

.js .loading::after {
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  margin: -30px 0 0 -30px;
  border-radius: 50%;
  opacity: 0.4;
  background: var(--color-link);
  animation: loaderAnim 0.7s linear infinite alternate forwards;
}

@keyframes loaderAnim {
  to {
    opacity: 1;
    transform: scale3d(0.5, 0.5, 1);
  }
}

a {
  text-decoration: none;
  color: var(--color-link);
  outline: none;
}

a:hover,
a:focus {
  color: var(--color-link-hover);
  outline: none;
}

.frame {
  padding: 1rem;
  text-align: center;
  position: relative;
  z-index: 1000;
}

.frame__title {
  font-size: 1rem;
  margin: 0 0 1rem;
  font-weight: normal;
}

.frame__links {
  display: inline;
}



.frame__social {
  margin: 1rem 0;
}
.btn {
  padding: 16px;
}
.btn svg * {
  fill: #442634;
  transition: .3s fill ease-out;
}
.btn svg {
  position: relative;
  transition: .3s scale ease-out;
}
.btn:hover svg {
  transform: scale(1.25);
}
.btn:hover svg *,
.btn:hover svg *{
  fill: #d8d7d7;
}

.follow-txt {
  height: 24px;
  line-height: 24px;
  padding: 1rem;
  display: inline-block;
  transform: translateY(-8px);
  color: #614551;
  opacity: 0;
  transition: .3s opacity ease-out;
}
.frame__social:hover .follow-txt {
  opacity: 1;
}

.frame__social--current:hover {
  color: var(--color-link-hover);
}
.frame__social--current {
  color: var(--color-link);
}

.content {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: calc(100vh - 5rem);
  position: relative;
  justify-content: flex-start;
  align-items: center;
}



@media screen and (min-width: 53em) {
  .frame {
    position: fixed;
    text-align: left;
    z-index: 100;
    top: 0;
    left: 0;
    display: grid;
    align-content: space-between;
    width: 100%;
    max-width: none;
    height: 100vh;
    padding: 1rem;
    pointer-events: none;
    grid-template-columns: 75% 25%;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      'title links'
      '... ...'
      '... social';
  }
  .frame__title-wrap {
    grid-area: title;
    display: flex;
  }
  .frame__title {
    margin: 0;
  }
  .frame__tagline {
    position: relative;
    margin: 0 0 0 1rem;
    padding: 0 0 0 1rem;
    opacity: 0.5;
  }
  .frame__social {
    margin: 0;
    grid-area: social;
    justify-self: end;
    display: flex;
  }
  .frame__links {
    grid-area: links;
    padding: 0;
    justify-self: end;
  }
  .frame a {
    pointer-events: auto;
  }
  .content {
    height: 100vh;
    justify-content: center;
  }
}

.mf-cursor{
  position:fixed;
  top:0;
  left:0;
  z-index:250;
  direction:ltr;
  contain:layout style size;
  pointer-events:none;
  transition:opacity .3s,color .4s
}
.mf-cursor:before{
  content:"";
  position:absolute;
  top:-24px;
  left:-24px;
  display:block;
  width:48px;
  height:48px;
  transform:scale(0.2);
  background:currentColor;
  border-radius:50%;
  transition:transform .25s ease-in-out,opacity .1s
}
.mf-cursor.-inverse{
  color:#381827;
  opacity: .5
}
@supports(mix-blend-mode: exclusion){
  .mf-cursor.-exclusion{mix-blend-mode:exclusion}
  .mf-cursor.-exclusion:before{background:#fff}
}
.mf-cursor.-pointer:before{
  transform:scale(0.15)
}
.mf-cursor.-text:before{
  opacity:.85;
  transform:scale(1.7)
}
.mf-cursor.-text.-active:before{
  transform:scale(1.6);
  transition-duration:.2s
}
.mf-cursor.-icon:before{
  transform:scale(1.5)
}
.mf-cursor.-icon.-active:before{
  transform:scale(1.4)
}
.mf-cursor.-hidden:before{
  transform:scale(0)
}
.mf-cursor-text{
  position:absolute;
  top:-18px;
  left:-18px;
  width:36px;
  height:36px;
  display:flex;
  align-items:center;
  justify-content:center;
  transform:scale(0) rotate(10deg);
  opacity:0;
  color:#fff;
  font-size:16px;
  line-height:20px;
  text-align:center;
  transition:opacity .4s,transform .3s
}
.mf-cursor.-text .mf-cursor-text,.mf-cursor.-icon .mf-cursor-text{
  opacity:1;
  transform:scale(1)
}
.mf-cursor-media{
  position:absolute;
  width:400px;
  height:400px;
  margin:-200px 0 0 -200px
}
.mf-cursor-media img,.mf-cursor-media video{
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
  border-radius:50%
}
@supports(object-fit: cover){
  .mf-cursor-media img,.mf-cursor-media video{
    position:static;
    width:100%;
    height:100%;
    object-fit:cover;
    transform:translateZ(0)
  }
  
}
.mf-cursor-media-box{
  position:relative;
  width:100%;
  height:100%;
  overflow:hidden;
  transform:scale(0) translateZ(0);
  padding:1px;
  opacity:0;
  border-radius:50%;
  transition:transform .35s,opacity .2s .2s
}
.mf-cursor.-media .mf-cursor-media-box{
  opacity:1;
  transform:scale(0.696);
  transition-duration:.4s,.4s;
  transition-delay:0s,0s
}
